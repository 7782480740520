import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-white text-[#7d4600]">
      <div className="w-3/5 mx-auto py-6 bg-white flex items-start justify-between">
        <p className="font-semibold text-xl">EDEN BEAUTE</p>
        <div>
          <p className="font-semibold">Nous trouver</p>
          <p>LE NAUTIL, CD 21, 77340 PONTAULT-COMBAULT</p>
        </div>

        <div>
          <p className="font-semibold">Nous contacter</p>
          <p>06 27 19 88 27</p>
        </div>

        <div>
          <p className="font-semibold">Mentions légales</p>
          <Link to={"mentions"}>
            <p>Mentions légales</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
