import FirstPage from "../Component/FirstPage";
import Header from "../Component/Header";
import Hero from "../Component/Hero";
// Import the functions you need from the SDKs you need
import { initializeApp,firebase } from "firebase/app";
import { getAnalytics,logEvent } from "firebase/analytics";
import { useEffect } from "react";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDxO_e7BARvkKIk0PpjTaISxXyWQKtvZF8",
  authDomain: "ednbeaute.firebaseapp.com",
  projectId: "ednbeaute",
  storageBucket: "ednbeaute.appspot.com",
  messagingSenderId: "424488956141",
  appId: "1:424488956141:web:e37cda00b73ae164d6dce4",
  measurementId: "G-36C4PWGE2T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const HomePage = () => {

    useEffect(() => {
        logEvent(analytics,'page_view', {
          page_path: "home"
        });
      }, []);
    
  return (
    <div>
      <FirstPage />
    </div>
  );
};

export default HomePage;
