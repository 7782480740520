const Price = () => {
  return (
    <div className="bg-black flex pt-10 flex-col items-center justify-center">
      <div className="w-3/5">
        <p className="text-[#7d4600] text-xl font-semibold">EPILATIONS</p>

        <div className="text-[#7d4600] flex flex-col space-y-4  rounded-2xl mt-4 bg-white p-10">
          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>Sourcil</span>
            <span>15mn - 10&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>Levre superieure</span>
            <span>15mn - 9&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>Menton</span>
            <span>15mn - 10&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>Visage</span>
            <span>20mn - 20&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>Avant bras</span>
            <span>15mn - 10&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>Bras</span>
            <span>15mn - 10&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>Aisselle</span>
            <span>15mn - 10&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>Maillot simple</span>
            <span>15mn - 10&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>Maillot echancré</span>
            <span>15mn - 10&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>Maillot semi-integral</span>
            <span>15mn - 10&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>Maillot integral</span>
            <span>15mn - 10&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>Jambes entieres</span>
            <span>15mn - 10&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>Demi jambes</span>
            <span>15mn - 10&euro;</span>
          </p>
        </div>
      </div>

      <div className="w-3/5 my-6">
        <p className="text-[#7d4600] text-xl font-semibold">
          FORFAIT EPILATIONS
        </p>

        <div className="text-[#7d4600] flex flex-col space-y-4  rounded-2xl mt-4 bg-white p-10">
          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>1/2 jambes + maillot simple + aisselles</span>
            <span>30 mn - 34&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>1/2 jambes + maillot simple + aisselles</span>
            <span>30mn - 38&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>1/2 jambes + maillot semi integral + aisselles</span>
            <span>30mn - 42&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>1/2 jambes + maillot integral + aisselles</span>
            <span>30mn - 45&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>jambes entieres + maillot simple + aisselles</span>
            <span>30 mn - 42&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>jambes entieres + maillot simple + aisselles</span>
            <span>30mn - 45&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>jambes entieres + maillot semi integral + aisselles</span>
            <span>30mn - 49&euro;</span>
          </p>

          <p className="flex items-center pb-2 border-b border-b-[#7d4600]  justify-between">
            <span>jambes entieres + maillot integral + aisselles</span>
            <span>30mn - 56&euro;</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Price;
